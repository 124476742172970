@import "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
button,
input,
* {
  font-family: "Inter";
}
main {
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 2em;
}
.player {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cell {
  aspect-ratio: 1/1;
}
.red {
  border: 1em #8d2d2d solid !important;
  color: #8d2d2d;
  background-color: white;
}
.red:hover, .red:focus {
  background-color: #8d2d2d;
  color: white;
}
.red.deck {
  outline: 1px white solid;
  box-shadow: #8d2d2d 10px 0, white 11px 0, #8d2d2d 20px 0, white 21px 0, #8d2d2d 28px 0;
}
.blue {
  border: 1em #1f1d83 solid !important;
  color: #1f1d83;
  background-color: white;
}
.blue:hover, .blue:focus {
  background-color: #1f1d83;
  color: white;
}
.blue.deck {
  outline: 1px white solid;
  box-shadow: #1f1d83 10px 0, white 11px 0, #1f1d83 20px 0, white 21px 0, #1f1d83 28px 0;
}
.gold {
  border: 1em #f2cd07 solid !important;
  color: #f2cd07;
  background-color: white;
}
.gold:hover, .gold:focus {
  background-color: #f2cd07;
  color: white;
}
.gold.deck {
  outline: 1px white solid;
  box-shadow: #f2cd07 10px 0, white 11px 0, #f2cd07 20px 0, white 21px 0, #f2cd07 28px 0;
}
.choice {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  justify-items: stretch;
  align-content: center;
  gap: 1em;
}
.cards {
  display: grid;
  width: 100%;
  margin-top: 1em;
  gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
@media (max-width: 720px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
.card {
  width: 100%;
  outline: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  aspect-ratio: 167/252;
  border-radius: 10px;
  cursor: pointer;
}
.card h1 {
  font-size: 4em;
}
.card h3 {
  font-weight: 400;
}
.card.disabled {
  cursor: not-allowed !important;
  filter: grayscale(30%);
}
.endButtons {
  display: flex;
  flex-direction: row;
  gap: 1em;
  width: 100%;
}
.endButtons > * {
  width: 100%;
}
button:not(.card) {
  outline: none;
  box-shadow: none;
  background-color: white;
  border: 3px black solid;
  color: black;
  padding: 1em;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
}
button:not(.card):hover {
  background-color: black;
  color: white;
}
#end {
  margin-top: 2em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8em;
}
table {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
}
table,
thead,
tr,
td,
th,
tbody {
  border: 3px solid black;
  text-align: left;
}
td,
th {
  padding: 8px;
}
.spacer {
  height: 1em;
}
.wide {
  width: 100%;
}
#score {
  padding: 4em;
}
.round {
  border-radius: 10px;
}
.nocolour {
  background-color: white;
  border: black 2px solid;
  color: black;
}
#home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
a {
  color: blue;
}
form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
form input {
  padding: 1.4em;
  text-align: center;
  font-size: 1em;
}
code {
  font-size: 2em;
}
#tutorial > div p {
  display: block;
}
#tutorial {
  height: 100vh;
}
#tutorial object {
  height: 100%;
}
.noStyle {
  text-decoration: none;
  color: inherit;
  margin: 1em;
  padding-bottom: 1em;
}
/*# sourceMappingURL=index.1b439652.css.map */
